import React, { useRef, useEffect } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { CrossX } from '../icons';
import * as Style from './Modal.styles';

export const ModalSizes = {
    narrow: 'narrow',
    wide: 'wide',
};

type PropsType = { children: React.ReactNode };

type ModalPropsTypes = PropsType & {
    size?: string;
    canClickOutside?: boolean;
    close?: (() => void) | null;
};

export const Modal = ({
    size = '',
    children,
    canClickOutside = false,
    close = null,
}: ModalPropsTypes): JSX.Element => {
    useEffect(() => {
        document.body.classList.add('modal-open');
        return () => document.body.classList.remove('modal-open');
    }, []);

    const modalContentRef = useRef(null);
    useOnClickOutside(modalContentRef, () => {
        close?.();
    });

    return (
        <Style.Modal>
            <Style.ModalDialog size={size} className={size}>
                <Style.ModalContent ref={canClickOutside ? modalContentRef : null}>
                    {children}
                </Style.ModalContent>
            </Style.ModalDialog>
        </Style.Modal>
    );
};

type ModalHeaderPropsTypes = PropsType & { closeHandler: (() => void) | null };

export const ModalHeader = ({ children, closeHandler }: ModalHeaderPropsTypes): JSX.Element => (
    <Style.ModalHeader>
        <Style.ModalTitle>{children}</Style.ModalTitle>
        {closeHandler && (
            <Style.CloseButton onClick={closeHandler}>
                <CrossX />
            </Style.CloseButton>
        )}
    </Style.ModalHeader>
);

export const ModalBody = Style.ModalBody; // eslint-disable-line prefer-destructuring

export const ModalFooter = ({ children }: PropsType): JSX.Element => (
    <Style.ModalFooter className="modal-footer">
        <div className="button-group text-right">{children}</div>
    </Style.ModalFooter>
);

export const ModalBackDrop = (): JSX.Element => <Style.ModalBackDrop />;
